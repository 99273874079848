const version="1.0.0";

const page_vars=getUrlVars();
let page_lng="es";
if(page_vars["lng"])
	page_lng=page_vars["lng"];

const server_base= location.hostname === "localhost" 
	? "http://localhost/farmaval/"
	: "https://www.farmaval.net/app/";
const server_url= server_base+"service_functions.php";

let service,lng=null;
let steps=[];

function show_lng(lng)
{
	document.querySelectorAll(".lng").forEach(element=>{
		element.style.display = 'none';
	});
	document.querySelectorAll(".lng_"+lng).forEach(element=>{
		element.style.display = 'inline-block';
	});	
	page_lng=lng;
	localStorage["farmaval_lng"]=lng;
}

function fill_lng(lng)
{
	document.querySelectorAll("[aria-lng]").forEach(element=>{
		if(lng[element.getAttribute("aria-lng")])
			element.innerHTML=lng[element.getAttribute("aria-lng")];
	});	
}

function lng_click(e)
{
	var lng=this.getAttribute("href");
	show_lng(lng);
	e.preventDefault();
}	

function getUrlVars() 
{
	var vars = {};
	var parts = window.location.href.replace(/[?&#]+([^=&#]+)=([^&#]*)/gi, function(m,key,value) 
	{
		vars[key] = value;
	});
	return(vars);
}

function change_step(step,direction)
{
	document.querySelector(".step_title").innerHTML=lng[service.steps[step].title];
	if(service.steps[step].info){
		document.querySelector(".step_info").innerHTML=lng[service.steps[step].info];
		document.querySelector(".step_info").classList.add("hide");
	}else
		document.querySelector(".step_info").classList.add("hide");

	if(document.querySelector(".step.visible")) 
		document.querySelector(".step.visible").classList.remove("visible");
	
	document.getElementById(step).classList.add("visible");

	document.querySelectorAll(".btn_step_next").forEach(element=>{
		if(service.steps[step].hidenext)
			element.classList.add("hide");	
		else
			element.classList.remove("hide");
	});

	document.querySelectorAll(".btn_step_prev").forEach(element=>{
		if(service.steps[step].hideprev)
			element.classList.add("hide");	
		else
			element.classList.remove("hide");
	});

	if(service.steps[step].load)
		js[service.steps[step].load]();

	if(direction!="prev") steps.push(step);

	document.querySelectorAll(".btn_step_prev").forEach(element=>{
		if(steps.length<=1)
			element.classList.add("disabled");
		else
			element.classList.remove("disabled");	
	});

	if(service.steps[step].focus)
		document.getElementById(service.steps[step].focus).focus();
}

async function show_step(step,direction=null)
{
	if(steps.length>0)
	{
		if(direction=="prev")
		{
			steps.pop()
			step=steps[steps.length-1];
		}
		if(direction=="next")
		{
			const next=service.steps[steps[steps.length-1]].next;
			step=null;
			for(let a=0;a<next.length;a++)
			{
				if(!next[a].function)
				{
					step=next[a].step;
					break;
				}else{
					let param=null;
					if(next[a].param) param=next[a].param;
					if(next[a].async)
					{
						const res=await js[next[a].function](param); 
						if(res){
							change_step(next[a].step,direction);
								return;
						}
						/*
						js[next[a].function](param,function(res)
						{
							if(res)
							{
								change_step(next[a].step,direction);
								return;
							}
						});*/
					}else{
						if(js[next[a].function](param))
						{
							step=next[a].step;
							break;
						}
					}
				}
			}
		}
	}

	if(step!=null)
		change_step(step,direction);
}

function fill_options()
{
	document.querySelectorAll(".options_row[aria-options]").forEach(element=>{
		element.innerHTML="";
		const options=lng[element.getAttribute("aria-options")].split("|");
		options.forEach(option=>{			
			const op=document.querySelector("#elements .option_item").cloneNode();
			const val=option.split("-");
			op.setAttribute("aria-value",val[1])
			op.append(val[0]);
			element.append(op);
		});
	});
}

function prepare_events()
{
	document.querySelectorAll("[trigger-enter]").forEach(element=>{
        element.addEventListener("keydown",function(e)
        {
			if(e.keyCode==13)
			{
				e.preventDefault();
				document.getElementById(element.getAttribute("trigger-enter")).click();
			}
        });
    });

	document.querySelectorAll("[trigger-click]").forEach(element=>{
        element.addEventListener("click",function(e)
        {
            setTimeout(function(e)
            {
                document.getElementById(element.getAttribute("trigger-click")).click();
            },500);
        });
    });

    document.querySelectorAll(".input_digit input").forEach(element=>{
        element.addEventListener("keydown",function(e)
        {
            if(e.keyCode==8)
            {
                const prev=element.previousElementSibling;
                if(prev)
                {
                    e.stopPropagation();
                    prev.focus();
                }
            }else                  
                if(element.value.length>0) element.value="";
        });
        element.addEventListener("keyup",function(e)
        {
            if(this.value.length==1) 
            {
                let next=element.nextElementSibling;
                if(next)
                {
					if(next.tagName.toLowerCase() !== 'input')
						next=next.nextElementSibling;
                    next.focus();
                }else{
                    setTimeout(function(e)
                    {
                        document.getElementById(element.parentElement.getAttribute("trigger-end")).click();
                    },500);
                }
            }
        });
    
    });

    document.querySelectorAll(".option_item").forEach(element=>{
        element.addEventListener("click",function(e)
        {
            e.preventDefault();
            
            if(element.parentElement.getAttribute("aria-check"))
            {
                this.classList.toggle("selected");
            }else{
                this.parentElement.querySelectorAll(".selected").forEach(el=>{
                    el.classList.remove("selected");
                });
                this.classList.add("selected");
            }
        });
    });
}

async function load()
{

	// prev / next
	document.querySelectorAll(".btn_step").forEach(element=>{
		element.addEventListener("click",function(e)
		{
			e.preventDefault();
			if(this.classList.contains("disabled")) return;
			show_step(null,this.getAttribute("aria-step"));
		});
	});
	
	document.querySelectorAll(".btn_continue").forEach(element=>{
		element.addEventListener("click",function(e)
		{
			e.preventDefault();
			show_step(null,"next");
		});
	});

	document.querySelectorAll(".btn_jump_to_step").forEach(element=>{
		element.addEventListener("click",function(e)
		{
			e.preventDefault();
			show_step(this.getAttribute("href"));
		});
	});

	service=js.data;
	lng=js.lngs[page_lng];

	//console.log(lng);

	// show service information special 
	document.querySelector(".service_version").innerHTML=service.version;
	
	// prepare options
	//fill_options();

	// show first step
	//show_step(service.start);

	// show service informatin
	//fill_lng(lng);
	//show_lng(page_lng);

	//prepare_events();

	js.service_init();

	// prepare options
	fill_options();

	// show first step
	show_step(service.start);

	// show service informatin
	fill_lng(lng);
	show_lng(page_lng);

	prepare_events();

	document.querySelectorAll(".btn_exit").forEach(element=>{
        element.addEventListener("click",function(e)
        {
		   e.preventDefault();
		   steps=[];
           show_step(service.main);
        });
    });

	// timer
	/*js.service_time=0;
	var myInterval = setInterval(function () {
		if(js.service_time==-1) return;
		js.service_time++;
		document.getElementById("service_time").innerHTML=
			  (parseInt(js.service_time/60) < 10 ? '0' : '') + parseInt(js.service_time/60)+":"+
			  (parseInt(js.service_time%60) < 10 ? '0' : '') + parseInt(js.service_time%60);
  	}, 1000);	
	*/
	//js.test();
}


document.addEventListener("DOMContentLoaded", load, false);

